<template>
  <q-item
    @click="click"
    :clickable="profile"
    :class="{ 'text-grey': !props.employer.active }">
    <q-item-section side>
      <Avatar :employer="props.employer"></Avatar>
    </q-item-section>
    <q-item-section>
      <q-item-label class="ellipsis" style="max-width: 200px">
        {{ props.employer.surname }} {{ props.employer.name }}
      </q-item-label>
      <q-item-label caption v-if="employer.super"> Руководитель </q-item-label>
      <q-item-label caption v-else-if="employer.role" class="text-grey">
        {{ props.employer.role?.name }}
      </q-item-label>
    </q-item-section>
  </q-item>
</template>

<script setup>
import { useRouter } from "vue-router";
import { defineProps } from "vue";
import Avatar from "./avatar.vue";

const props = defineProps({
  employer: Object,
  online: {
    type: Boolean,
    default: false,
  },
  profile: {
    type: Boolean,
    default: false,
  },
  short: {
    type: Boolean,
    default: false,
  },
});
const router = useRouter();

const click = () => {
  if (!props.profile) {
    return;
  }
  router.push({ name: "profile", params: { id: props.employer.id } });
};
</script>
