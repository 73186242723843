<template>
  <q-select
    dense
    options-dense
    standout
    use-input
    :options="filtered"
    :use-chips="multiple"
    :multiple="multiple"
    @filter="filter"
    :model-value="props.modelValue"
    @update:model-value="emit('update:modelValue', $event)"
    :label="
      label || (multiple ? 'Выберите сотрудников' : 'Выберите сотрудника')
    "
    option-label="full_name"
    option-value="id"
    options-selected-class="text-primary">
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">
          Сотрудников не найдено
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:option="scope">
      <q-item clickable v-bind="scope.itemProps">
        <q-item-section side>
          <Avatar :employer="scope.opt"></Avatar>
        </q-item-section>
        <q-item-section>
          <q-item-label class="ellipsis" style="max-width: 200px">
            {{ scope.opt.full_name }}
          </q-item-label>
          <q-item-label caption class="text-grey" v-if="scope.opt.super">
            Руоводитель
          </q-item-label>
          <q-item-label caption class="text-grey" v-else-if="scope.opt.role">
            {{ scope.opt.role.name }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script setup>
import { useStore } from "@/store";
import { ref, defineProps, defineEmits, computed, toRefs } from "vue";
import Avatar from "../employers/avatar.vue";

const props = defineProps({
  modelValue: Object,
  multiple: Boolean,
  label: String,
});
const { multiple, label } = toRefs(props);
const search = ref("");
const emit = defineEmits(["update:modelValue"]);
const store = useStore();

const filtered = computed(() => {
  const lowerSearch = search.value.toLocaleLowerCase();
  return store.employers.filter((item) => {
    if (!lowerSearch) {
      return true;
    }
    const email = item.email?.toLocaleLowerCase() || "";
    const name = item.full_name?.toLocaleLowerCase() || "";
    const phone = item.phone || "";
    return (
      email.indexOf(lowerSearch) > -1 ||
      name.indexOf(lowerSearch) > -1 ||
      phone.indexOf(lowerSearch) > -1
    );
  });
});

const filter = (q, done) => {
  search.value = q;
  done();
};
</script>
