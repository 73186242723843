<template>
  <q-btn dense flat icon="mic" @click="isModalOpen = true"></q-btn>
  <q-dialog v-model="isModalOpen">
    <q-card style="width: 300px">
      <q-toolbar>
        <q-toolbar-title>Голосовая конференция</q-toolbar-title>
        <q-btn dense flat icon="close" v-close-popup></q-btn>
      </q-toolbar>
      <q-card-section>
        <div>
          <ul>
            <li v-for="user in store.online" :key="user.id">
              <label>
                <input
                  type="checkbox"
                  :value="user.id"
                  v-model="selectedUsers" />
                {{ user.name }}
              </label>
            </li>
          </ul>
        </div>
        <button @click="startConference">Начать звонок</button>
        <div v-if="isInConference">
          <p>Подключенные пользователи:</p>
          <ul>
            <li v-for="participant in participants" :key="participant.id">
              {{ participant.name }}
            </li>
          </ul>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { confirm, play } from "@/helpers";
import { useStore } from "@/store";
import { ref, onMounted, onUnmounted, reactive } from "vue";

const store = useStore();
const selectedUsers = ref([]);
const participants = ref([]);
const isInConference = ref(false);
const isModalOpen = ref(false);
const iceCandidatesBuffer = [];

let localStream;
const peerConnections = reactive({});

const playStream = (event) => {
  const stream = event.streams[0] || event.track;
  const audio = new Audio();
  audio.srcObject = stream;
  audio.play();
};

const startConference = async () => {
  isInConference.value = true;
  localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
  selectedUsers.value.forEach((userId) => callUser(userId));
};

const callUser = (userId) => {
  store.companyChannel.whisper("call", { from: store.employer.id, to: userId });
};

const handleIncomingCall = async ({ from, to }) => {
  if (to != store.employer.id) {
    return;
  }
  // Уведомление о входящем звонке
  const sound = play("call");
  const isConfirm = await confirm(`Принять звонок от пользователя ${from}?`);
  sound.pause();
  if (isConfirm) {
    localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    store.companyChannel.whisper("callAccepted", {
      from: store.employer.id,
      to: from,
    });
    setupPeerConnection({ from, to });
  }
};

const setupPeerConnection = async ({ from, to }) => {
  if (to != store.employer.id) {
    return;
  }
  participants.value.push({
    id: from,
    name: `User ${from}`,
  });
  const peerConnection = new RTCPeerConnection({
    iceServers: [
      {
        urls: "stun:stun1.l.google.com:19302",
        username: store.employer.name,
        credential: store.employer.id,
      },
    ],
  });

  peerConnections[from] = peerConnection;

  localStream
    .getTracks()
    .forEach((track) => peerConnection.addTrack(track, localStream));

  peerConnection.onicecandidate = ({ candidate }) => {
    if (candidate) {
      store.companyChannel.whisper("iceCandidate", {
        from: store.employer.id,
        to: from,
        candidate,
      });
    }
  };

  peerConnection.ontrack = (event) => {
    playStream(event);
  };

  const offer = await peerConnection.createOffer();
  await peerConnection.setLocalDescription(offer);

  store.companyChannel.whisper("offer", {
    from: store.employer.id,
    to: from,
    offer,
  });
};

const handleAnswer = async ({ from, to, answer }) => {
  if (to !== store.employer.id) return;

  const peerConnection = peerConnections[from];
  await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
};

const handleOffer = async ({ from, offer, to }) => {
  if (to !== store.employer.id) {
    return;
  }
  const peerConnection = new RTCPeerConnection({
    iceServers: [
      {
        urls: "stun:stun1.l.google.com:19302",
        username: store.employer.name,
        credential: store.employer.id,
      },
    ],
  });
  peerConnections[from] = peerConnection;

  // Обработчик ICE-кандидатов
  peerConnection.onicecandidate = ({ candidate }) => {
    if (candidate) {
      store.companyChannel.whisper("iceCandidate", {
        from: store.employer.id,
        to: from,
        candidate,
      });
    }
  };

  // Получение медиатрека
  peerConnection.ontrack = (event) => {
    playStream(event);
  };

  // Установить удалённое описание
  await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));

  // Добавить все буферизированные ICE-кандидаты
  iceCandidatesBuffer.forEach(async (candidate) => {
    try {
      await peerConnection.addIceCandidate(candidate);
    } catch (error) {
      console.error(
        "Ошибка добавления буферизированного ICE-кандидата:",
        error
      );
    }
  });
  iceCandidatesBuffer.length = 0;

  const answer = await peerConnection.createAnswer();
  await peerConnection.setLocalDescription(answer);

  store.companyChannel.whisper("answer", {
    from: store.employer.id,
    to: from,
    answer,
  });
};

const handleIceCandidate = async ({ from, to, candidate }) => {
  if (to !== store.employer.id) return;

  const peerConnection = peerConnections[from];

  // Если удалённое описание ещё не установлено, буферизуем кандидата
  if (!peerConnection || !peerConnection.remoteDescription) {
    iceCandidatesBuffer.push(candidate);
    return;
  }

  // Если описание установлено, добавляем кандидата сразу
  try {
    await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
  } catch (error) {
    console.error("Ошибка добавления ICE-кандидата:", error);
  }
};

onMounted(() => {
  store.companyChannel.listenForWhisper("call", handleIncomingCall);
  store.companyChannel.listenForWhisper("callAccepted", setupPeerConnection);
  store.companyChannel.listenForWhisper("offer", handleOffer);
  store.companyChannel.listenForWhisper("answer", handleAnswer);
  store.companyChannel.listenForWhisper("iceCandidate", handleIceCandidate);
});

onUnmounted(() => {
  for (let pc of peerConnections) {
    pc.close();
  }
  if (localStream) {
    localStream.getTracks().forEach((track) => track.stop());
  }
});
</script>
