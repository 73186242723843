export const MeetingTimes = [
  { label: "30 мин", value: 30 },
  { label: "1 час", value: 60 },
  { label: "1 час 30 мин", value: 90 },
  { label: "2 часа", value: 120 },
  { label: "2 часа 30 мин", value: 150 },
  { label: "3 часа", value: 180 },
];
export const MeetingTimesMap = {
  30: "30 мин",
  60: "1 час",
  90: "1 час 30 мин",
  120: "2 часа",
  150: "2 часа 30 мин",
  180: "3 часа",
};
export const TaskPriorityMap = {
  1: "positive",
  2: "amber",
  3: "negative",
};
export const Statuses = {
  new: "Новый",
  work: "В работе",
  pause: "Пауза",
  done: "Завершен",
};
export const ActionTypes = [
  { value: "employer", label: "Назначить ответственного" },
  { value: "employers_queue", label: "Назначить очередь ответственных" },
  { value: "task", label: "Создать задачу" },
  { value: "notification_user", label: "Отправить уведомление сотруднику" },
  { value: "complete", label: "Завершить сделку" },
  { value: "destroy", label: "Удалить" },
];

export const FileIcons = {
  audio: require("./assets/files/audio.svg"),
  video: require("./assets/files/video.svg"),
  doc: require("./assets/files/doc.svg"),
  image: require("./assets/files/image.svg"),
  pdf: require("./assets/files/pdf.svg"),
  ppt: require("./assets/files/ppt.svg"),
  text: require("./assets/files/text.svg"),
  unknown: require("./assets/files/unknown.svg"),
  xls: require("./assets/files/xls.svg"),
  zip: require("./assets/files/zip.svg"),
  code: require("./assets/files/code.svg"),
};

export const LevelIcons = [
  require("./assets/levels/1.svg"),
  require("./assets/levels/2.svg"),
  require("./assets/levels/3.svg"),
  require("./assets/levels/4.svg"),
  require("./assets/levels/5.svg"),
  require("./assets/levels/6.svg"),
  require("./assets/levels/7.svg"),
  require("./assets/levels/8.svg"),
  require("./assets/levels/9.svg"),
  require("./assets/levels/10.svg"),
  require("./assets/levels/11.svg"),
  require("./assets/levels/12.svg"),
  require("./assets/levels/13.svg"),
  require("./assets/levels/14.svg"),
  require("./assets/levels/15.svg"),
  require("./assets/levels/16.svg"),
  require("./assets/levels/17.svg"),
  require("./assets/levels/18.svg"),
  require("./assets/levels/19.svg"),
  require("./assets/levels/20.svg"),
  require("./assets/levels/21.svg"),
  require("./assets/levels/22.svg"),
  require("./assets/levels/23.svg"),
  require("./assets/levels/24.svg"),
  require("./assets/levels/25.svg"),
  require("./assets/levels/26.svg"),
  require("./assets/levels/27.svg"),
  require("./assets/levels/28.svg"),
  require("./assets/levels/29.svg"),
  require("./assets/levels/30.svg"),
];

export const Currencies = [
  {
    label: "Рубль",
    value: "rub",
  },
];

export const ContactTypes = [
  {
    label: "Телефон",
    value: "phone",
  },
  {
    label: "E-mail",
    value: "email",
  },
];

export const ContactTypesMap = {
  phone: "Телефон",
  email: "E-mail",
};

export const Timezones = [
  { label: "GMT +00:00", value: "Europe/London" },
  { label: "GMT +01:00", value: "Europe/Berlin" },
  { label: "GMT +02:00", value: "Europe/Kaliningrad" },
  { label: "GMT +03:00", value: "Europe/Moscow" },
  { label: "GMT +04:00", value: "Europe/Samara" },
  { label: "GMT +05:00", value: "Asia/Yekaterinburg" },
  { label: "GMT +06:00", value: "Asia/Omsk" },
  { label: "GMT +07:00", value: "Asia/Novosibirsk" },
  { label: "GMT +08:00", value: "Asia/Irkutsk" },
  { label: "GMT +09:00", value: "Asia/Yakutsk" },
  { label: "GMT +10:00", value: "Asia/Vladivostok" },
  { label: "GMT +11:00", value: "Asia/Sakhalin" },
  { label: "GMT +12:00", value: "Asia/Kamchatka" },
];
