<template>
  <div
    class="flex column q-mt-lg q-col-gutter-y-md justify-center items-center">
    <div class="col-md-6 text-center">
      <div class="text-h5">Добро пожаловать в RostPoint.</div>
      <div class="text-h6">
        Перед началом работы ознакомьтесь с основными возможностями системы.
      </div>
    </div>
    <div class="col-md-6">
      <q-btn
        color="positive"
        label="Открыть базу знаний"
        no-caps
        to="faq"></q-btn>
    </div>
  </div>
</template>

<script setup></script>
